export const TOKEN_KEY = "token";

export const ROLES: { [key: string]: string } = {
  "1000:system": "System",
  "900:master": "Master",
  "800:admin": "Admin",
  "700:supervisor": "Supervisor",
};

export const API_URL = "https://api.niseclub.com";
